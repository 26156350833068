<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="D3 Operation Experience" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full mb-6">
        <div class="m-auto mb-4" style="width: 640px;">
          <div
            class="fusion-column-wrapper"
            style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
            data-bg-url=""
          >
            <span
              class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
              ><img
                src="@/assets/RiskManagement/D3-logos-min.png"
                width="640"
                height="100"
                alt=""
                title="D3-logos"
                class="img-responsive wp-image-12044"
            /></span>
            <div class="fusion-clearfix"></div>
          </div>
        </div>
        <TitleComponent
          title="Explosives Safety Principles Successfully Applied to Decommissioning of Operating Buildings"
        />
      </div>
      <div class="w-full mb-6">
        <Paragraph
          ><p>
            SMS provided key safety and design support for the decommissioning,
            decontamination and demolition (D3) operations of the Pratt &amp;
            Whitney Rocketdyne (PWR) Chemical Systems Division manufacturing
            facility located in San Jose, California (2004 – 2009). Since the
            early 1960s, PWR scientists and engineers developed and manufactured
            advanced rocket propulsion systems to support space exploration,
            national defense, and international communications.
          </p>
          <p>
            The PWR facility consisted of over 230 structures on 5,200 acres.
            The structures supported manufacturing of large-scale solid rocket
            motors (grinding, feed systems, mixing/casting, curing, etc.),
            manufacturing of liquid rocket motors, research and development
            activities, liquid and solid rocket motor test systems, energetic
            destruction and waste treatment systems, site maintenance, and
            administration.
          </p>
          <p>
            ARCADIS was the prime contractor with team members including SMS
            (explosives safety), VES (specialty and high hazard
            decontamination), PIKA (thermal treatment), and Sevenson
            (demolition, rigging, and earthwork). Utilizing systematic risk
            management, all high-hazard buildings with associated processing
            equipment have been safely removed, requiring over 900,000 man hours
            worked under difficult and challenging circumstances with no Lost
            Time injuries.
          </p>
          <p>
            SMS has applied proven hazards analysis methodologies in the
            approach to assessment, decontamination and demolition of equipment
            and materials. The material recovered that was deemed unsafe to
            handle or transport was first treated or safely destroyed on site.
            Thermal verification of equipment was employed when appropriate.
          </p>
          <p>
            In coordination with VES and ARCADIS personnel, systems were
            developed to safely and accurately assess, categorize, inventory,
            disassemble and decontaminate equipment and facilities. SMS was
            instrumental in evaluating the suitability of various technologies
            for remote pipe cutting, boroscope inspection, thermal and/or
            chemical treatment, and destruction in place. SMS performed
            qualitative and quantitative hazards analyses as part of the
            evaluation to establish proper design and operating specifications
            based on potential risks and the associated hazard ranking (severity
            and probability).
          </p>
          <p>
            SMS explosives classification expertise was utilized to ensure that
            equipment and facilities were decontaminated and disassembled to a
            point that they were safe to handle and either transport to a
            disposal facility or transport to a third party for reuse or
            recycle, based on the intrinsic value of the material/item. The
            energetic material recovered that was deemed unsafe to handle or
            transport was first treated or safely destroyed on site.
          </p>
          <p>
            SMS regularly provided formal training on Explosives Recognition and
            Decontamination to over 550 personnel performing work in the field
            over the course of the project.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "D3 Operation Experience",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS was the key safety and design support for D3 operations for the Pratt & Whitney Rocketdyne (PWR) Chemical Systems Division manufacturing facility located in San Jose, California (2004 – 2009). This project consisted of 230 buildings and over 900,000 man hours."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
